
import { Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SiteMap from './SiteMap';


const MapSite = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className="app-banner">
      <div className="hfb-logo">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.houstonfoodbank.org/upcomingevent/share-your-holidays/"
        >
          <img alt="hfb-logo" src="../images/hfb_logo.png" />
        </a>
      </div>
      <div className="sponsor-logo-wrapper">
        <img alt="hfb-logo" style={{marginInline:15, height:110}} src="../images/sponsor_1.jpg" />
        <img alt="hfb-logo" style={{marginInline:15}} src="../images/sponsor_2.jpg" />
        <img alt="hfb-logo" style={{marginInline:15, height:110}} src="../images/syh_logo.png" />
        <img alt="hfb-logo" style={{marginInline:15, height:110}} src="../images/sponsor_3.jpg" />
        <img alt="hfb-logo" style={{marginInline:15, height:110}} src="../images/sponsor_4.jpg" />
      </div>
      <div className="action-wrapper">
        <Button
          target="_blank"
          href="https://www.houstonfoodbank.org/upcomingevent/share-your-holidays/"
          color="secondary"
          variant="outlined"
        >
          Learn More
        </Button>
      </div>
      <div className="action-wrapper">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate('/admin/pantryList')}
        >
          Admin
        </Button>
      </div>
    </div>
    <SiteMap />
    </>
  );
};

export default MapSite;
