import gql from 'graphql-tag';

export default gql`
  mutation($input: PantryReservationInput!) {
    createPantryReservation(input: $input) {
      idReferal
      pantryName
      pantryAddress
      pantryContact
      pantryPhone
      selectedTime
      name
      email
      zip
      homePhone
      comments
      preferredContactMethod
    }
  }
`;
