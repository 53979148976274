import { isString, isArray, isEmpty, isBoolean } from 'lodash';

export const required = (value: any) => {
  if (isString(value) && !value.trim()) return 'Required';
  if (isArray(value) && isEmpty(value)) return 'Required';
  if (!value && typeof value !== 'number' && !isBoolean(value))
    return 'Required';
  return undefined;
};

export const validZipCode = (value: string) => {
  if (value) {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);

    if (!isValidZip) {
      return 'Invalid Zip Code';
    }

    return undefined;
  }

  return undefined;
};

export const validTrue = (value: any) => {
  if (value !== true) {
    return 'Required';
  }

  return undefined;
};

export const validPhoneNumber = (value: any) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value.replace(/-/g, ''))
    ? 'Phone must be 10 digits'
    : undefined;

export const validDate = (value: any) =>
    value && !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(value.replace(/-/g, ''))
      ? 'Invalid date'
      : undefined;

export const validEmail = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const validSmallNumber = (value: any) =>
    value > 10
      ? 'Number too large'
      : undefined;