import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Grid,
  Backdrop,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Alert from '@material-ui/lab/Alert';
import omit from 'lodash/omit';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import { SubmissionError, Field, Form, reduxForm } from 'redux-form';

import { pantrySitesQuery } from '../apollo';
import createPantryReservation from '../apollo/mutations/createPantryReservation';
import { SUPPORT_ENGLISH, SUPPORT_SPANISH } from '../config';
import idTypes from '../constants/idTypes';
import states from '../constants/states';
import getErrorMessage from '../helpers/getErrorMessage';
import { normalizePhone } from '../helpers/normalizers';
import { parseNumber } from '../helpers/parsers';
import {
  required,
  validPhoneNumber,
  validEmail,
  validZipCode,
  validDate,
  validSmallNumber,
  validTrue,
} from '../helpers/validators';

import Captcha from './Captcha';
import DropDownMenu from './form-fields/DropDownMenu';
import RadioButtonGroup from './form-fields/RadioButtonGroup';
import TextField from './form-fields/TextField';
import contactMethods from '../constants/contactMethods';
import Checkbox from './form-fields/CheckBox';
import { style } from '@mui/system';

const RegisterForm = reduxForm({ form: 'registerForm' })((props: any) => {
  const {
    handleSubmit,
    submitting,
    onClose,
    preRegistration,
    selectedSite,
    availabilityOptions,
    registerFunction,
    dirty,
    submitFailed,
    valid,
    error,
  } = props;

  if (!selectedSite) return null;

  const {
    Name,
    Address1,
    Address2: City,
    Zip,
    Phone,
    Contact,
    exceededLimitWarning,
  } = selectedSite;

  const [contactSelected, setContactSelected] = useState<string>('E-Mail');
  const [emailHasValue, setEmailHasValue] = useState<boolean>(false);

  return (
    <>
      <Backdrop invisible open={submitting} style={{ zIndex: 99 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Form autoComplete="off" onSubmit={handleSubmit(registerFunction)}>
        <DialogTitle className="notranslate">
          Share Your Holidays Registration
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {exceededLimitWarning && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  You have exceeded the number of pre-registration slots that
                  was agreed upon with your partner representative. Please be
                  advised that you can continue pre-registering clients but will
                  need to have the same number of community registrations. For
                  more information please contact your agency representative.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <div className="pantry-info-title">
                Partner Agency Information / Informacion de la Agencia:
              </div>
              <div className="pantry-info-wrapper">
                <div className="pantry-info">
                  <div>Name / Nombre:&nbsp;</div>
                  <div className="notranslate">{Name}</div>
                </div>
                <div className="pantry-info">
                  <div>Zip / Codigo Postal:&nbsp;</div>
                  <div className="notranslate">{Zip}</div>
                </div>
                <div className="pantry-info">
                  <div>Phone / Telefono:&nbsp;</div>
                  <div className="notranslate">{Phone}</div>
                </div>
                <div className="pantry-info">
                  <div>Contact / Contacto:&nbsp;</div>
                  <div className="notranslate">{Contact}</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                className="notranslate"
                name="selectedTime"
                label="Available Dates &amp; Times / Fechas & Horarios:"
                component={RadioButtonGroup}
                validate={required}
                options={availabilityOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Field
                name="firstName"
                component={TextField}
                label="First Name / Primer Nombre"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="lastName"
                component={TextField}
                label="Last Name / Apellido"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="email"
                component={TextField}
                onChange={(value:any) => value.target.value != '' ? setEmailHasValue(true): setEmailHasValue(false)}
                label="Email (Not required)/ Correo Electronico (Opcional)"
                validate={[validEmail]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="homePhone"
                component={TextField}
                label="Cell Phone / Telefono movil"
                required
                validate={[required, validPhoneNumber]}
                normalize={normalizePhone}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <Field
                name="zip"
                component={TextField}
                label="Zip / Codigo Postal"
                required
                validate={[required, validZipCode]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
            <div className="pantry-info">
                  <div>If someone else will be picking up / Si alguien mas recogera:&nbsp;</div>
            </div>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comments"
                component={TextField}
                label="Name of Proxy / Nombre del Representante"
                fullWidth
                multiline
                rows={3}
                custom={{style:{marginTop: 20}}}
              />
            </Grid>
            {!window.location.href.includes("volunteer") && (
              <Grid item xs={12} style={{ height: 100 }}>
                <Field
                  name="captcharesponse"
                  component={Captcha}
                  validate={required}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                name="confirm"
                component={Checkbox}
                label="By checking this box, I agree to receive text messages and emails from Houston Food Bank at the mobile number and email address provided above. Text message and data rates may apply. / Al marcar esta casilla, acepto recibir mensajes de texto y correos electrónicos del Houston Food Bank al número de teléfono móvil y a la dirección de correo electrónico proporcionados anteriormente. Es posible que se apliquen tarifas de mensajes de texto y datos."
                validate={[required, validTrue]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {
                <>
                  {contactSelected == 'Phone' && 
                   <Alert severity='info'>
                      By Submiting the Registration your consent to receive SMS messages.
                   </Alert>
                  }
                  {!error && dirty && !valid && submitFailed && (
                    <Alert severity="warning">
                      {`Complete all required fields${
                        preRegistration ? '' : ' and human verification'
                      }`}
                    </Alert>
                  )}
                  {error && (
                    <Alert severity="error">
                      <div>{error}</div>
                      <div style={{ marginTop: 10 }}>
                        If you need assistance please contact Houston Food Bank:
                      </div>
                      <div>
                        English:&nbsp;
                        <a href={`tel:${SUPPORT_ENGLISH}`}>{SUPPORT_ENGLISH}</a>
                      </div>
                      <div>
                        Spanish:&nbsp;
                        <a href={`tel:${SUPPORT_SPANISH}`}>{SUPPORT_SPANISH}</a>
                      </div>
                    </Alert>
                  )}
                </>
              }
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            <Button
              onClick={onClose}
              color="primary"
              disabled={submitting}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </>
  );
});

const RegisterResponse = ({
  onClose,
  preRegistration,
  setShowResponse,
  showResponse,
  selectedSite,
}: any) => {
  const {
    pantryName,
    pantryAddress,
    pantryPhone,
    selectedTime,
    name,
    email,
    idType,
    numberAdults,
    numberChildren,
    idReferal
  } = showResponse;

  console.log(showResponse)
  return (
    <>
      <div className="register-print-info">
        <b>Thank you for making a reservation for Share Your Holidays.</b>
        <br />
        <div>Please be sure to be on time for your reservation.</div>
        <br />
        <b>Registrant Details:</b>
        <div>
          <b>Key:&nbsp;</b>
          {idReferal}
        </div>
        <div>
          <b>Name:&nbsp;</b>
          {name}
        </div>
        <div>
          <b>Email:&nbsp;</b>
          {email}
        </div>
        <br />
        <b>Selected Partner Agency:</b>
        <div className="notranslate">{pantryName}</div>
        <div className="notranslate">{pantryAddress}</div>
        <br />
        <b>Selected Time:</b>
        <div className="notranslate">{selectedTime}</div>
        <br />
        <div>
          If you have any questions, please contact {pantryName} at{' '}
          <a className="notranslate" href={`tel:${pantryPhone}`}>
            {pantryPhone}
          </a>
          .
        </div>
        <br />
        Thank you and Happy Holidays,
        <br />
        The Houston Food Bank
      </div>
      <DialogTitle className="register-info" style={{ textAlign: 'center' }}>
        Registration Successful
      </DialogTitle>
      <DialogContent className="register-info">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CheckCircleIcon color="primary" style={{ fontSize: 60 }} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            Your registration was submitted successfully.
            <div>&ndash;</div>
            You will receive a confirmation email and phone message shortly.
            <div>&ndash;</div>
            <Button
              onClick={() => window.print()}
              color="secondary"
              variant="outlined"
            >
              Print Confirmation
            </Button>
          </Grid>
        </Grid>
        <DialogActions
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
        >
          {preRegistration && (
            <Button
              onClick={() => setShowResponse(false)}
              color="secondary"
              variant="outlined"
              disabled={!selectedSite}
            >
              Register Another
            </Button>
          )}

          <Button onClick={onClose} color="primary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

const Register = (props: any) => {
  const [showResponse, setShowResponse] = useState<any>(false);
  const [registerMutation] = useMutation(createPantryReservation, {
    onCompleted({ createPantryReservation }) {
      if (createPantryReservation) {
        setShowResponse(createPantryReservation);
      } else {
        throw new SubmissionError({
          _error: 'Submission Error, please try again.',
        });
      }
    },
    onError(error) {
      throw new SubmissionError({ _error: getErrorMessage(error) });
    },
    refetchQueries: [{ query: pantrySitesQuery }],
  });

  const registerFunction = (values: any) => {
    return registerMutation({
      variables: {
        input: {
          preferredContactMethod: 'E-Mail',
          volunteer: window.location.href.includes("volunteer"),
          ...omit(values, ['captcharesponse', 'confirm']),
        },
      },
    });
  };

  const availabilityOptions = useMemo(() => {
    return props?.selectedSite?.times.map(({ sDate, sTime }: any) => {
      const timeFormatted = moment
        .utc(sDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        .format('M/D/YYYY');

      return `${timeFormatted} -- ${sTime}`;
    });
  }, [props]);

  if (showResponse) {
    return (
      <RegisterResponse
        {...props}
        setShowResponse={setShowResponse}
        showResponse={showResponse}
        selectedSite={props?.selectedSite}
      />
    );
  }

  let initialValues = {
    pantryId: props?.selectedSite?.Pantry,
  } as any;

  if (availabilityOptions?.length === 1) {
    initialValues = {
      ...initialValues,
      selectedTime: availabilityOptions[0],
      preferredContactMethod: 'E-Mail', 
      confirm: null
    };
  }

  return (
    <RegisterForm
      {...props}
      availabilityOptions={availabilityOptions}
      registerFunction={registerFunction}
      initialValues={initialValues}
    />
  );
};

export default Register;
