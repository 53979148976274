import MarkerClusterer from '@googlemaps/markerclustererplus';
import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import { pantrySitesQuery } from '../apollo';
import {
  SUPPORT_SPANISH,
  SUPPORT_ENGLISH,
  AVAILABLE_COLOR,
  UNAVAILABLE_COLOR,
} from '../config';

import InfoDialog from './InfoDialog';
import Register from './Register';
import RegistrationDialog from './RegistrationDialog';
import SearchInput from './SearchInput';

let map: google.maps.Map;
let markers: google.maps.Marker[] = [];
let infoWindow: google.maps.InfoWindow;
let autocomplete: google.maps.places.Autocomplete;
let marker: google.maps.Marker;
let markerCluster: MarkerClusterer;
let popup: Popup;
let input: HTMLInputElement;

class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  constructor(position: google.maps.LatLng, content: HTMLElement) {
    super();
    this.position = position;

    content.classList.add('popup-bubble');
    const bubbleAnchor = document.createElement('div');
    bubbleAnchor.classList.add('popup-bubble-anchor');
    bubbleAnchor.appendChild(content);
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-container');
    this.containerDiv.appendChild(bubbleAnchor);
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  onAdd() {
    this.getPanes().floatPane.appendChild(this.containerDiv);
  }

  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position,
    );

    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}

const SiteMap = () => {
  const [showInfo, setShowInfo] = useState<boolean>(
    Boolean(!localStorage?.getItem('SYH_Visited')),
  );
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [position, setPosition] = useState<any>(null);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [resetVisible, setResetVisible] = useState<boolean>(false);
  const [mobileView, setMobileView] = useState<boolean>(false);

  const { data } = useQuery(pantrySitesQuery);

  useEffect(() => {
    localStorage?.setItem('SYH_Visited', 'TRUE');
  });

  useEffect(() => {
    if (!showInfo) {
      setShowToolTip(true);
    }

    setTimeout(() => {
      setShowToolTip(false);
    }, 4000);
  }, [showInfo]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600 || window.innerHeight < 600) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();
  }, []);

  const shiftedCoordinates = useCallback(
    (latlng: any, isUser = false) => {
      const offsetx = 0;
      let offsety;
      if (mobileView && !isUser) {
        offsety = -200;
      } else if (isUser) {
        offsety = 0;
      } else {
        offsety = -75;
      }

      const scale = Math.pow(2, map.getZoom());

      const worldCoordinateCenter = map
        ?.getProjection()
        ?.fromLatLngToPoint(latlng);
      const pixelOffset = new google.maps.Point(
        offsetx / scale || 0,
        offsety / scale || 0,
      );

      if (worldCoordinateCenter) {
        const worldCoordinateNewCenter = new google.maps.Point(
          worldCoordinateCenter.x - pixelOffset.x,
          worldCoordinateCenter.y + pixelOffset.y,
        );

        const shifted = map
          ?.getProjection()
          ?.fromPointToLatLng(worldCoordinateNewCenter);

        return shifted;
      }

      return latlng;
    },
    [mobileView],
  );

  useEffect(() => {
    infoWindow?.close();

    if (selectedSite) {
      const {
        Name,
        Address1,
        Address2: City,
        Zip,
        Phone,
        availability,
        times,
        marker,
      } = selectedSite;

      const locationUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
        `${Name}, ${Address1}, ${City}, TX, ${Zip}`,
      )}`;

      const timeStrings = times.map(({ sDate, sTime }: any) => {
        const timeFormatted = moment
          .utc(sDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          .format('M/D/YYYY');

        return `<div>${timeFormatted} - ${sTime}</div>`;
      });

      let contentString =
        '<div>' +
        `<div class="info-title notranslate">${Name}</div>` +
        '<div class="info-sub-title">Address</div>' +
        `<a class="info-address first notranslate" target="_blank" href="${locationUrl}">${Address1}</a>` +
        `<br />` +
        `<a class="info-address second notranslate" target="_blank" href="${locationUrl}">${City} TX, ${Zip}</a>` +
        `<br />` +
        '<div class="info-sub-title">Phone</div>' +
        `<a class="info-phone" href="tel:${Phone}">${Phone}</a>` +
        '<div class="info-times notranslate">' +
        '<div class="info-sub-title translate">Available Dates & Times</div>' +
        `${timeStrings.join('')}` +
        '</div>' +
        '</div>' +
        `<button id="register-btn">Register</button>`;

      if (!availability) {
        contentString =
          '<div>' +
          `<div class="info-title notranslate">${Name}</div>` +
          '<div class="info-sub-title">Address</div>' +
          `<a class="info-address first notranslate" target="_blank" href="${locationUrl}">${Address1}</a>` +
          `<br />` +
          `<a class="info-address second notranslate" target="_blank" href="${locationUrl}">${City} TX, ${Zip}</a>` +
          `<br />` +
          '<div class="info-sub-title">Phone</div>' +
          `<a class="info-phone" href="tel:${Phone}">${Phone}</a>` +
          '<div class="info-times notranslate">' +
          '<div class="info-sub-title translate">Available Dates & Times</div>' +
          'None' +
          '</div>' +
          '</div>' +
          `<div class="info-not-available">If you need assistance please contact Houston Food Bank:</div>` +
          `<div>English:&nbsp;<a href=tel:${SUPPORT_ENGLISH}>${SUPPORT_ENGLISH}</a></div>` +
          `<div>Spanish:&nbsp;<a href=tel:${SUPPORT_SPANISH}>${SUPPORT_SPANISH}</a></div>`;
      }

      infoWindow = new google.maps.InfoWindow({
        content: contentString,
        disableAutoPan: true,
        zIndex: 10,
      });

      if (availability) {
        google.maps.event.addListenerOnce(infoWindow, 'domready', function () {
          google.maps.event.addDomListener(
            document.getElementById('register-btn') as any,
            'click',
            function () {
              setShowRegister(true);
            },
          );
        });
      }

      infoWindow.open(map, marker);

      const markerPosition = marker.getPosition();
      const shifted = shiftedCoordinates(markerPosition);

      infoWindow.addListener('closeclick', () => setSelectedSite(null));

      map.panTo(shifted);
    }
  }, [selectedSite, shiftedCoordinates]);

  const initializeData = useCallback(() => {
    markers = [];
    data?.pantrySites?.pantries?.map((pantrySite: any) => {
      const markerColor = pantrySite?.availability
        ? 'red'
        : 'grey';

      const marker = new google.maps.Marker({
        position: {
          lat: pantrySite.Lat,
          lng: pantrySite.Long,
        },
        icon: {
          url:
            'data:image/svg+xml;base64,' +
            btoa(
              `<svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" width="36" height="36"><path fill="${markerColor}" stroke="#031A06" stroke-width="1%" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>`,
            ),
        },
      }) as any;

      marker.addListener('click', () =>
        setSelectedSite({
          ...pantrySite,
          marker,
        }),
      );
      markers.push(marker);

      return {
        ...pantrySite,
        marker,
      };
    });

    markerCluster = new MarkerClusterer(map, markers, {
      zoomOnClick: false,
      title: 'Click to view',
      imagePath: `${process.env.PUBLIC_URL}/images/m`,
      averageCenter: true,
      gridSize: 45,
      ignoreHidden: true,
    }) as any;

    google.maps.event.addListener(markerCluster, 'clusterclick', (cluster) => {
      setSelectedSite(null);
      map.setCenter(cluster.getCenter());
      if (map.getZoom() >= 10) {
        map.setZoom(map.getZoom() + 2);
      } else {
        map.setZoom(map.getZoom() + 1);
      }
    });

    setInitialLoading(false);
    input.focus();
  }, [data]);

  const resetSearch = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    marker.setVisible(false);
    setPosition(null);
    input.value = '';
    input.placeholder = 'Enter your location to find nearby partners';
    input.focus();
  };

  const resetMap = () => {
    infoWindow?.close();
    setSelectedSite(null);
    marker.setVisible(false);
    map.panTo({ lat: 29.7604, lng: -95.3698 });
    map.setZoom(10);
    setResetVisible(false);
    setPosition(null);
    input.value = '';
    input.placeholder = 'Enter your location to find nearby partners';
  };

  useEffect(() => {
    if (position) {
      popup?.setMap(map);
      popup.position = new google.maps.LatLng(position.coordinates);
      const popupElement = popup.containerDiv.getElementsByClassName(
        'popup-bubble',
      )[0] as HTMLElement;
      popupElement.innerText = position.name;
    } else {
      popup?.setMap(null);
    }
  }, [position]);

  const initializeAutocomplete = useCallback(() => {
    setPosition(null);
    input.value = '';
    input.placeholder = 'Enter your location to find nearby partners';
    autocomplete?.unbindAll();
    google.maps?.event?.clearInstanceListeners(input);
    autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'us' },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setFields(['geometry', 'name']);
    autocomplete.addListener('place_changed', () => {
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place?.geometry) {
        window.alert(
          `No details available${place?.name ? ` for '${place?.name}'` : ''}`,
        );
        autocomplete.unbindAll();
        google.maps.event.clearInstanceListeners(input);
        setPosition(null);
        input.value = '';
        input.placeholder = 'Enter your location to find nearby partners';
        return;
      }

      input.placeholder = `Showing agencies near ${place?.name || ''}`;
      input.value = '';

      const { lat, lng } = place.geometry?.location;
      const position = { lat: lat(), lng: lng() };

      map.setZoom(12);
      marker.setPosition(place.geometry.location);
      map.panTo(shiftedCoordinates(marker.getPosition(), true));
      marker.setVisible(true);
      setPosition({
        coordinates: position,
        name: place?.name,
      });
    });
  }, [shiftedCoordinates]);

  useEffect(() => {
    if (data) {
      map = new google.maps.Map(
        document.getElementById('map-container') as HTMLElement,
        {
          center: { lat: 29.7604, lng: -95.3698 },
          zoom: 10,
          mapTypeControl: false,
          streetViewControl: false,
          clickableIcons: false,
          fullscreenControl: false,
          gestureHandling: 'cooperative',
        },
      );

      input = document.getElementById('search-input-base') as HTMLInputElement;

      marker = new google.maps.Marker({
        map,
        icon: `${process.env.PUBLIC_URL}/images/pin.png`,
      });

      if (!popup) {
        popup = new Popup(
          new google.maps.LatLng({ lat: 29.7604, lng: -95.3698 }),
          document.getElementById('content') as HTMLElement,
        );
      }

      initializeAutocomplete();

      google.maps.event.addListenerOnce(map, 'tilesloaded', initializeData);

      google.maps.event.addListener(map, 'zoom_changed', () => {
        setResetVisible(true);
      });

      google.maps.event.addListener(map, 'center_changed', () => {
        setResetVisible(true);
      });
    }
  }, [data, initializeAutocomplete, initializeData]);

  return (
    <div className="app-wrapper">
      <div id="google_translate_element" />
      <div style={initialLoading ? { visibility: 'hidden' } : {}} id="content">
      </div>
      <div id="map-container" />
      {!initialLoading && (
        <InfoDialog open={showInfo} setShowInfo={setShowInfo} />
      )}
      <div
        className="search-wrapper"
        style={initialLoading ? { visibility: 'hidden' } : {}}
      >
        <Tooltip
          enterTouchDelay={100}
          arrow
          title="Show Help"
          open={initialLoading ? false : showToolTip}
          onOpen={() => setShowToolTip(true)}
          onClose={() => setShowToolTip(false)}
        >
          <IconButton
            aria-label="help"
            onClick={() => setShowInfo(true)}
            style={{ height: 50, width: 50 }}
          >
            <svg
              className="MuiSvgIcon-root help-icon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill="#FFF"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
              ></path>
            </svg>
          </IconButton>
        </Tooltip>
        <SearchInput
          resetMap={resetMap}
          resetSearch={resetSearch}
          resetVisible={resetVisible}
        />
      </div>
      <RegistrationDialog
        open={showRegister}
        onClose={() => setShowRegister(false)}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Register
          onClose={() => setShowRegister(false)}
          selectedSite={selectedSite}
          preRegistration={data?.pantrySites?.preRegistration}
        />
      </RegistrationDialog>
    </div>
  );
};

export default SiteMap;
